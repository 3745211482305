import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Widget from "../components/widget"
import Breadcrumbs from "../components/seo/breadcrumbs"
import { getHrefLangs } from "../core/QueryTransformer"

import rehypeReact from "rehype-react"
import Bubble from "../components/bubble"
import SimpleBox from "../components/simple-box"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    bubble: Bubble,
    box: SimpleBox,
  },
}).Compiler

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext: { pages, locale, home },
}) {
  // extract data for the location post
  const { locationPost, hreflangEdges, homePage, regionPost } = data
  const { frontmatter, htmlAst } = locationPost

  let breadcrumbs = [
    {
      url: homePage.frontmatter.url,
      title: homePage.frontmatter.title,
    },
    {
      url: regionPost.frontmatter.url,
      title: regionPost.frontmatter.title,
    },
    {
      url: locationPost.frontmatter.url,
      title: locationPost.frontmatter.title,
    },
  ]

  let currentLocale = frontmatter.locale
  let hreflangs = getHrefLangs(hreflangEdges.edges)

  // get featured image fluid
  // let featuredImgFluid =
  //   frontmatter.featuredImage && frontmatter.featuredImage.childImageSharp.fluid

  return (
    <>
      <Layout
        hreflangs={hreflangs}
        bg={`/images/${locationPost.slug}.jpg`}
        pages={pages.filter(page => page.locale === frontmatter.locale)}
        title={frontmatter.title}
        mode="simple"
        locale={locale}
        home={home}
      >
        <SEO
          title={frontmatter.metatitle}
          hreflangs={hreflangs}
          slug={frontmatter.slug}
          locale={currentLocale}
          description={frontmatter.metadescription}
          img={`/images/${frontmatter.slug}.jpg`}
        />
        <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>

        <div className="hidden-lg ">
          <div className="inner-page--jumbotron">
            {/* <h1 className="inner-page--header">{frontmatter.heading}</h1> */}
            <div className="inner-page--jumbotron-image-gradient"></div>
            <img
              src={`/images/${frontmatter.slug}.jpg`}
              alt={frontmatter.metatitle}
              className="inner-page--jumbotron-image"
            />
            <Widget
              locale={locale}
              options="noborder,notitle"
              heading={frontmatter.heading}
              from={frontmatter.from}
              to={frontmatter.to}
            ></Widget>
          </div>
        </div>
        <div className="main-body">
          <section className="container main-body inner-page">
            <div className="row">
              <div className="col-12 visible-lg">
                <div className="inner-page--header-container">
                  <img
                    src={`/images/${frontmatter.slug}.jpg`}
                    alt={frontmatter.metatitle}
                    className="inner-page--image-thumb"
                  />
                  <span className="h1 inner-page--header">
                    {frontmatter.heading}
                  </span>
                </div>
              </div>

              <div className="col-lg-8">{renderAst(htmlAst)}</div>
              <div className="col-lg-4 no-margin">
                <div className="visible-lg sticky">
                  <Widget
                    locale={locale}
                    options="noborder,notitle"
                    from={frontmatter.from}
                    to={frontmatter.to}
                  ></Widget>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($url: String!, $locale: String!, $slug: String!, $region: String!) {
    locationPost: markdownRemark(
      frontmatter: { url: { eq: $url }, locale: { eq: $locale } }
    ) {
      htmlAst
      frontmatter {
        title
        heading
        metatitle
        locale
        region
        type
        url
        description
        metadescription
        slug
        from
        to
        #featuredImage {
        #   childImageSharp {
        #     fluid(maxWidth: 800) {
        #       ...GatsbyImageSharpFluid
        #     }
        #   }
        #}
      }
    }
    regionPost: markdownRemark(
      frontmatter: {
        locale: { eq: $locale }
        region: { eq: $region }
        type: { eq: "region" }
      }
    ) {
      frontmatter {
        title
        url
      }
    }
    homePage: markdownRemark(
      frontmatter: { locale: { eq: $locale }, type: { eq: "homepage" } }
    ) {
      frontmatter {
        title
        url
      }
    }
    hreflangEdges: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            url
            locale
          }
        }
      }
    }
  }
`
